import visa from '#/shop-payment-credit-card/assets/logo_visa.svg'
import visaDebit from '#/shop-payment-credit-card/assets/logo_visadebito.png'
import mastercard from '#/shop-payment-credit-card/assets/logo_master.svg'
import maestro from '#/shop-payment-credit-card/assets/logo_maestro.png'
import amex from '#/shop-payment-credit-card/assets/logo_amex.svg'
import cabal from '#/shop-payment-credit-card/assets/logo_cabal.png'

export default {
	visa,
	'visa-debit': visaDebit,
	'mastercard-debit': mastercard,
	mastercard,
	maestro,
	amex,
	'amex-prisma': amex,
	cabal,
}
